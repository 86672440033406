import { useState, useEffect } from 'react';
import { Table, Input } from 'antd';
import { Link, useLocation } from "react-router-dom";

export const TableComponent = ({ data = [], search = false, color = 'black', rowPerPage = 10 }) => {
    const loaction = useLocation();
    const [dataSource, setDataSource] = useState(data);
    const [nameValue, setNameValue] = useState('');
    const [patientIdValue, setPatientIdValue] = useState('');

    useEffect(() => {
        if (data) {
            data.sort((a, b) =>  new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
            setDataSource(data);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    const FilterByPatientInput = (
        <Input placeholder="Search Patient Id" value={patientIdValue}
            onChange={e => {
                const currValue = e.target.value;
                setPatientIdValue(currValue);
                const filteredData = data.filter(entry =>
                    entry.patient_identifier.toLowerCase().includes(currValue.toLowerCase())
                );
                setDataSource(filteredData);
            }}
        />
    );

    const FilterByNameInput = (
        <Input placeholder="Search Name" value={nameValue}
            onChange={e => {
                const currValue = e.target.value;
                setNameValue(currValue);
                const filteredData = data.filter(entry =>
                    entry.name.toLowerCase().includes(currValue.toLowerCase())
                );
                setDataSource(filteredData);
            }}
        />
    );

    const filterByKey = (data, key) => {
        return [...new Map(data.map(item => [item[key], item])).values()].map(da => ({ text: da[key], value: da[key] }));
    }

    const columns = [
        {
            title: '',
            dataIndex: '',
            key: 'color',
            render: () => <div style={{ width: '5px', height: '50px', background: color }}></div>,
        },
        {
            title: !search ? FilterByPatientInput : 'Patient Id',
            dataIndex: 'patient_identifier',
            key: 'patient_identifier',
            render: (text, record) => <Link to={`/visitsummary/${record.key}`} state={{ ...loaction.state, data }} >{text}</Link>,
        },
        // {
        //     title: 'Abha No.',
        //     dataIndex: 'abha_no',
        //     key: 'abha_no',
        // },
        {
            title: !search ? FilterByNameInput : 'Name',
            dataIndex: 'name',
            key: 'name',
            width: 250
        },
        {
            title: 'Gender',
            dataIndex: 'gender',
            key: 'gender',
        },
        {
            title: 'Visit Date',
            dataIndex: 'created_at',
            key: 'created_at',
            sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
            render: (text) => new Date(text).toLocaleDateString('en-US')
        },
        // {
        //     title: 'D.O.B',
        //     dataIndex: 'dob',
        //     key: 'dob',
        //     render: (text) => new Date(text).toLocaleDateString('en-US')
        // },
        {
            title: 'Location',
            key: 'location',
            dataIndex: 'location',
            filters: filterByKey(data, 'location'),
            onFilter: (value, record) => record.location.includes(value)
        },
    ];

    if (!search) {
        columns.push({
            title: 'Provider',
            key: 'provider',
            dataIndex: 'provider',
            filters: filterByKey(data, 'provider'),
            onFilter: (value, record) => record.provider.includes(value)
        })
    }

    return (
        <>
            <Table columns={columns} dataSource={dataSource} pagination={{ pageSize: rowPerPage }} />
        </>
    )
}